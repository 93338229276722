import {store} from "../../../../store/index";
import Api from "../../../../store/Services/Api";

class ManageSalesOrderService{
    
    getFilteredSalesOrder(queryParams)
    {
        const url = `api/get-sales-orders?page-index=${queryParams.pageIndex}`
        const params ={
            'branch':queryParams.branch,
            'sales-type': queryParams.sales_type,
            'today': queryParams.today,
            'month':queryParams.month,
            'year':queryParams.year,
            'from':queryParams.from_date,
            'to':queryParams.to_date,
            'searched':queryParams.searched,
            'page-reload':queryParams.page_reload,
            'offset':queryParams.offset
        }
        const headers ={
            'Accept':'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.get(url,headers,params);
        
    }
    
    getSlipPredefinedData(){
        const url = `api/sales/slip/create`
        const headers ={
            'Accept':'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.get(url,headers);

    }

    cancelSaleOrder(id,data){
       
        const url = `api/sales/orders/cancel/${id}`
        const headers ={
            'Accept':'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.patch(url,data,headers);
    }
}

export default new ManageSalesOrderService();