import Sales from "./SalesService";
import SalesOrder from "./SalesOrderService";
import SalesOrderCheckout from "./SalesOrderCheckoutService";
import ManageSales from "./ManageSalesService";
import ManageSalesOrder from "./ManageSalesOrderService";
import SalesSetting from "./SalesSetting";

export default{
    ManageSales,
    ManageSalesOrder,
    Sales,
    SalesOrder,
    SalesOrderCheckout,
    SalesSetting
}

