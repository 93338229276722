import {store} from "../../../../store/index";
import Api from "../../../../store/Services/Api";

class SalesOrderCheckoutService{
    getCheckoutData(id)
    {
        const url = `api/sales/orders/checkout/${id}`
        const headers ={
            'Accept':'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.get(url,headers);
    }

    storeCheckoutSalesData(data){
        const url = 'api/sales/store/checkout/sales-order'
        const headers ={
            'Accept':'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.post(url,data,headers);
    }
}

export default new SalesOrderCheckoutService();