import {store} from "../../../../store/index";
import Api from "../../../../store/Services/Api";

class SalesOrderService{
    getDataForSalesOrderCreation(){
        const url = 'api/sales/orders/create'
        const headers ={
            'Accept':'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.get(url,headers);
    }

    storeSalesOrderData(salesOrderData){
        const url = 'api/sales/orders/store'
        const headers ={
            'Accept':'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.post(url,salesOrderData,headers);
    }

    getSalesPurchaseItemsByBranch(id){
        const url = `api/sales/orders/branch/${id}`
        const headers ={
            'Accept':'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.get(url,headers);
    }

    updateSalesOrderData(salesOrderData,id)
    {
        const url = `api/sales/orders/${id}`
        const headers ={
            'Accept':'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.patch(url,salesOrderData,headers);
    }
}

export default new SalesOrderService();