import {store} from "../../../../store/index";
import Api from "../../../../store/Services/Api";

class SalesService{
    getDataForSalesCreation(){
        const url = 'api/sales/create'
        const headers ={
            'Accept':'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.get(url,headers);
    }

    getPurchaseItemsByBranch(id){
        const url = `api/sales/items/branch/${id}`
        const headers ={
            'Accept':'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.get(url,headers);
    }

    getDataByBarcode(barcode, branch){
        const url = `api/sales/barcode/${barcode}/${branch}`
        const headers ={
            'Accept':'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.get(url,headers);
    }

    getItemsBySearchKeyword(params){
        const url = `api/sales/items/search`
        const headers ={
            'Accept':'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.get(url,headers,params);
    }

    getCustomersBySearchKeyword(keyword){
        const url = `api/customer/search/${keyword}`
        const headers ={
            'Accept':'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.get(url,headers);
    }

    storeSalesData(salesData)
    {
        const url = `api/sales/store`
        const headers ={
            'Accept':'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.post(url,salesData,headers);
    }

    updateSalesData(id,salesData){
        const url = `api/sales/${id}/update`
        const headers ={
            'Accept':'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.patch(url,salesData,headers);
    }

    updatePrintBillCount(salesId)
    {
        const url = `api/sales/update/sales-bill-count`
        const headers ={
            'Accept':'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.patch(url,salesId,headers);
    }
    getEditDataForSales(id){
        const url = `api/sales/edit/${id}`
        const headers ={
            'Accept':'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.get(url,headers);
    }
}

export default new SalesService();